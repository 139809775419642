import React, {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useState
} from 'react'
import type { TrajectoriesQuery } from 'src/generated/graphql/types'
import { ChartTabValue, TopBarValue } from 'src/provider/params'
import type { PerimeterValue } from 'src/common/hooks/usePerimeter'

const DEFAULT_VALUES = {
  topTab: TopBarValue.emission,
  chartTab: ChartTabValue.global,
  entitiesFilter: {
    organizationNodeIds: [],
    contributingEntityIds: []
  },
  selectedTrajectory: undefined,
  setTopTab: () => null,
  setChartTab: () => null,
  setEntitiesFilter: () => null,
  setSelectedTrajectory: () => null
}

type ActionPlanForecastChartProps = {
  topTab: TopBarValue
  chartTab: ChartTabValue
  entitiesFilter: PerimeterValue
  selectedTrajectory: TrajectoriesQuery['listTrajectories']['results'][0] | undefined
  setTopTab: Dispatch<SetStateAction<TopBarValue>>
  setChartTab: Dispatch<SetStateAction<ChartTabValue>>
  setEntitiesFilter: Dispatch<SetStateAction<PerimeterValue>>
  setSelectedTrajectory: Dispatch<SetStateAction<TrajectoriesQuery['listTrajectories']['results'][0] | undefined>>
}

export const ActionPlanForecastChartContext = createContext<ActionPlanForecastChartProps>(DEFAULT_VALUES)

const ActionPlanForecastChartProvider = ({ children }: PropsWithChildren) => {
  const [topTab, setTopTab] = useState<TopBarValue>(DEFAULT_VALUES.topTab)
  const [chartTab, setChartTab] = useState<ChartTabValue>(DEFAULT_VALUES.chartTab)
  const [entitiesFilter, setEntitiesFilter] = useState<PerimeterValue>(DEFAULT_VALUES.entitiesFilter)
  const [selectedTrajectory, setSelectedTrajectory] = useState<
    TrajectoriesQuery['listTrajectories']['results'][0] | undefined
  >()

  const value = useMemo(() => {
    return {
      topTab,
      chartTab,
      entitiesFilter,
      selectedTrajectory,
      setChartTab,
      setTopTab,
      setEntitiesFilter,
      setSelectedTrajectory
    }
  }, [topTab, chartTab, entitiesFilter, selectedTrajectory])

  return <ActionPlanForecastChartContext.Provider value={value}>{children}</ActionPlanForecastChartContext.Provider>
}

export default ActionPlanForecastChartProvider
