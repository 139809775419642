import { type PropsWithChildren, useMemo } from 'react'
import { message } from 'antd'
import { MessageAPIContext } from 'src/provider/MessageAPIContext'
import { ApolloError } from '@apollo/client'
import { useTypedTranslation } from 'src/common/utils'

export const MessageAPIProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTypedTranslation()
  const [messageApi, contextHolder] = message.useMessage()

  const customMessageApi = useMemo(
    () => ({
      ...messageApi,
      apiError: (e: unknown) => {
        console.error(e)
        if (e instanceof ApolloError) {
          message.error(e.message)
        } else {
          message.error(t('common.error_message'))
        }
      }
    }),
    [messageApi]
  )

  return (
    <MessageAPIContext.Provider value={customMessageApi}>
      {contextHolder}
      {children}
    </MessageAPIContext.Provider>
  )
}
