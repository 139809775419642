import React, { type PropsWithChildren, useState } from 'react'
import { ImageDecoratorContext } from 'src/common/editor/nodes/image/ImageDecoratorContext'

export function ImageDecoratorProvider({ children }: PropsWithChildren) {
  const [startResize, setStartResize] = useState(false)
  const [initialX, setInitialX] = useState(0)
  return (
    <ImageDecoratorContext.Provider value={{ startResize, setStartResize, initialX, setInitialX }}>
      {children}
    </ImageDecoratorContext.Provider>
  )
}
