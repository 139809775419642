export const UnleashFlag = {
  ReleaseCampaigns: 'release_campaigns',
  ReleaseMetrics: 'release_metrics',
  NZIDashboardsEnabled: 'nzi_dashboards_enabled',
  ForceReduceDisplay: 'force_reduce_display',
  ReduceDebugEnabled: 'reduce_debug_enabled',
  OffsetProjectsEnabled: 'offset_projects_enabled',
  FeatureTeasers: 'feature_teasers',
  ModuleLCAEnabled: 'module_lca_enabled',
  ModuleSupplyChainEnabled: 'module_supply_chain_enabled',
  ModuleReduceEnabled: 'module_reduce_enabled',
  ModuleReduceFeatureFinancialForecastEnabled: 'module_reduce_feature_financial_forecast_enabled',
  FeatureApiKey: 'feature_api_key',
  FeatureReduceSitePerSiteForecast: 'feature_reduce_site_per_site_forecast',
  ExportIndicatorValuesForRatp: 'export_indicator_values_for_ratp',
  FeatureDataSharingEnabled: 'feature_data_sharing_enabled',
  UnsafeDataSharingEnabled: 'unsafe_data_sharing_enabled',
  BeamerEnabled: 'beamer_enabled',
  BeamerUserAuthenticationEnabled: 'beamer_user_authentication_enabled',
  PlanhatIntegrationEnabled: 'planhat_integration_enabled',
  HideEmissionItemList: 'hide_emission_item_list',
  ReportingModule: 'reporting_module',
  ReleaseSurveys: 'release_surveys',
  ImpactFactorVersionsEnabled: 'impact_factor_versions_enabled',
  ReleaseDashboardMultiCategories: 'release_dashboard_multi_categories',
  ConnectV1: 'module_connect_v1',
  ConnectV2: 'module_connect_v2',
  TasklistExport: 'tasklist_export',
  CustomMenuItem: 'custom_menu_item',
  LanguageManagement: 'language_management',
  DeletableLanguages: 'deletable_languages',
  ImportCategories: 'import_categories',
  DisplayCopyAnalysis: 'display_copy_analysis',
  ReleaseExtrapolations: 'release_extrapolations',
  HideCrispCTA: 'hide_crisp_cta'
}
