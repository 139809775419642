import { InputNumber as AntdInputNumber, type InputNumberProps as AntdInputNumberProps, Tooltip } from 'antd'
import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import { useRef, useEffect, useState } from 'react'
import { useTypedTranslation } from 'src/common/utils'
const isValidNumberFormat = (input: string) => {
  // Regular expression to check for valid number (decimal using a dot, no commas, e or other characters)
  const validNumberRegex = /^-?\d*(\.\d+)?$/
  return validNumberRegex.test(input)
}

type HelpPosition = 'bottom' | 'tooltip'

type ValueType = number | string
type InputNumberProps<T extends ValueType = ValueType> = Omit<
  AntdInputNumberProps<T>,
  'controls' | 'ref' | 'step' | 'autoComplete' | 'status' | 'type' | 'onStep'
> & {
  children?: React.ReactNode
  helpPosition?: HelpPosition
} & React.RefAttributes<HTMLInputElement>

export const InputNumber = <T extends ValueType = ValueType>({
  helpPosition = 'tooltip',
  onChange,
  value: propsValue,
  onBlur,
  onPaste,
  ...props
}: InputNumberProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState(false)
  const [value, setValue] = useState(propsValue)
  const { t } = useTypedTranslation()

  const handleChange = (newValue: T | null) => {
    setValue(newValue)
    if (newValue === null || isNumber(newValue)) {
      onChange?.(newValue)
    }
  }
  const triggerErrorFor5Seconds = () => {
    setError(true)
    setTimeout(() => setError(false), 5000)
  }

  useEffect(() => {
    const current = inputRef.current
    if (current) {
      const stopPropagation = (event: WheelEvent) => event.stopPropagation()
      const preventDefault = (event: WheelEvent) => event.preventDefault()
      let isCtrlPressed = false
      const unPressCtrl = (event: KeyboardEvent) => {
        if (event.key === 'Control' || event.key === 'Meta') {
          isCtrlPressed = false
        }
      }
      const pressCtrl = (event: KeyboardEvent) => {
        if (event.key === 'Control' || event.key === 'Meta') {
          isCtrlPressed = true
        }
      }
      const preventTypingLetters = (event: KeyboardEvent) => {
        pressCtrl(event)
        if (isCtrlPressed) {
          // allow ctrl + c, ctrl + v, ctrl + x, ctrl + a and ctrl + z to work
          return
        }
        const isALetterOrSpecialChar = /^[a-zA-Z\s!@#$%^&*()_=[\]{};':,+"\\|<>/?]$/
        if (event.key !== null && isALetterOrSpecialChar.test(event.key)) {
          event.preventDefault()
        }
      }
      current.addEventListener('wheel', stopPropagation, { passive: false })
      current.addEventListener('focusin', () => {
        current.addEventListener('wheel', preventDefault)
        current.addEventListener('keydown', preventTypingLetters)
        current.addEventListener('keyup', unPressCtrl)
      })
      current.addEventListener('focusout', () => {
        current.removeEventListener('wheel', preventDefault)
        current.removeEventListener('keydown', preventTypingLetters)
        current.removeEventListener('keyup', unPressCtrl)
      })
      return () => current.removeEventListener('wheel', stopPropagation)
    }
  }, [])

  useEffect(() => {
    setValue(propsValue)
  }, [propsValue])

  return (
    <Tooltip title={helpPosition === 'tooltip' ? t('common.form.inputNumber.format_helper') : undefined}>
      <AntdInputNumber<T>
        ref={inputRef}
        onBlur={event => {
          const elementValue = event.target.value
          if (!isValidNumberFormat(elementValue)) {
            // make the input blink to indicate that the value was changed
            triggerErrorFor5Seconds()
            setValue(undefined)
            onChange?.(null)
          }
          onBlur?.(event)
        }}
        controls={false}
        onPaste={event => {
          const pastedValue = event.clipboardData.getData('text')
          if (!isValidNumberFormat(pastedValue)) {
            triggerErrorFor5Seconds()
          }
          onPaste?.(event)
        }}
        step={0}
        autoComplete="off"
        status={error ? 'error' : undefined}
        onChange={handleChange}
        value={value}
        {...props}
      />
      {helpPosition === 'bottom' && (
        <div
          className={classNames('text-gray-500 italic mt-1 transition-all', {
            'text-red-700': error,
            'text-xs': !error,
            'text-sm': error
          })}
        >
          {t('common.form.inputNumber.format_helper')}
        </div>
      )}
    </Tooltip>
  )
}
