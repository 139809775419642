import { Breadcrumb, Layout, Spin } from 'antd'
import { type ReactNode } from 'react'

type ContainerProps = {
  children: ReactNode
  className?: string
  loading?: boolean
  breadcrumbItems?: string[]
}

export default function Container({ className, children, breadcrumbItems, loading }: ContainerProps) {
  return (
    <Layout className="px-4 min-h-full mb-10">
      {breadcrumbItems && (
        <Breadcrumb className="my-4">
          {breadcrumbItems.map(item => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}

      <Layout.Content
        className={`site-layout-background p-6 ${className ? className : ''}`}
        style={{
          margin: 0,
          minHeight: 280
        }}
      >
        {loading ? (
          <div className="flex items-center justify-center h-[280px]">
            <Spin />
          </div>
        ) : (
          children
        )}
      </Layout.Content>
    </Layout>
  )
}
