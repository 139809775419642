import React, { useMemo } from 'react'
import { type SetURLSearchParams, useSearchParams } from 'react-router-dom'

interface SearchParamsContextType {
  searchParams: URLSearchParams
  setSearchParams?: SetURLSearchParams
}

export const SearchParamsContext = React.createContext<SearchParamsContextType>({
  searchParams: new URLSearchParams()
})

export const SearchParamsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const contextValue = useMemo(() => ({ searchParams, setSearchParams }), [searchParams, setSearchParams])
  return <SearchParamsContext.Provider value={contextValue}>{children}</SearchParamsContext.Provider>
}
