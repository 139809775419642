import { useListMetricsQuery } from 'src/generated/graphql/types'
import type { Metric, Unit } from 'src/type'
import { useTypedTranslation } from 'src/common/utils'
import { type ReactNode } from 'react'
import { TranslatedValue } from 'src/common/designSystem'
import isUndefined from 'lodash/fp/isUndefined'

export const GHG_INDUCED_EMISSIONS = 'GHG_INDUCED_EMISSIONS'

type MetricsProps = {
  includeArchived?: boolean
}

export function useMetrics({ includeArchived }: MetricsProps = { includeArchived: false }) {
  const { getTranslatedValue } = useTypedTranslation()
  const { data, loading } = useListMetricsQuery({
    variables: { offset: 0, limit: 1000, includeArchived }
  })
  const getMetric = (id: string | undefined): Metric | undefined => {
    if (!id) return undefined
    return data?.listMetrics.results.find(metric => metric.id === id)
  }

  const getReferenceUnitLabel = (id: string | undefined): string | undefined => {
    if (!id) return undefined
    const metric = getMetric(id)
    return `${metric?.unitGroup.referenceUnit.shortLabel ?? ''}${
      metric?.unitAttributeSuffix ? ` ${metric.unitAttributeSuffix}` : ''
    }`
  }

  const defaultMetric = getMetric(GHG_INDUCED_EMISSIONS)

  const getReferenceUnit = (id: string | undefined): Unit | undefined => {
    const metric = getMetric(id)
    if (!metric) return defaultMetric?.unitGroup.referenceUnit
    return metric.unitGroup.referenceUnit
  }

  const getMetricLabel = (id: string | undefined): ReactNode => {
    const metric = getMetric(id)
    if (!metric) return undefined
    return isUndefined(getTranslatedValue(metric.label).value) ? undefined : <TranslatedValue fields={metric.label} />
  }

  return {
    defaultMetric,
    loading,
    getMetric,
    getReferenceUnit,
    getReferenceUnitLabel,
    getMetricLabel,
    isMultiMetrics: (data?.listMetrics.totalCount ?? 0) > 1
  }
}
