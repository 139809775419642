import React from 'react'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import type { Locale } from 'antd/lib/locale'
import antFrLocale from 'antd/locale/fr_FR'
import antDeLocale from 'antd/locale/de_DE'
import { useTypedTranslation } from 'src/common/utils'
import { traaceTheme } from 'src/theme'

const antLocales: { [key: string]: Locale } = {
  fr: antFrLocale,
  de: antDeLocale,
  en: antEnLocale
}

export function AntConfigProvider({ children }: { children: React.ReactNode }) {
  const { i18n } = useTypedTranslation()
  return (
    <ConfigProvider locale={antLocales[i18n.language] ?? antEnLocale} theme={traaceTheme}>
      {children}
    </ConfigProvider>
  )
}
