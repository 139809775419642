import { Slider } from 'antd'
import { type ReactNode } from 'react'
import { InputNumber } from 'src/common/form'

export function SettingsSlider(props: {
  min?: number
  max?: number
  value: number
  className?: string
  extra?: ReactNode
  onChange: (value: number) => void
  displayUnit?: string | null
}) {
  const { className, min, max, value, onChange, extra } = props
  return (
    <div className={`${className ?? ''} flex flex-wrap gap-4 items-center`}>
      <div className="flex-grow" style={{ minWidth: '150px' }}>
        <Slider min={min} max={max} value={value} onChange={value => onChange(value)} />
      </div>
      <div className="flex-shrink">
        <InputNumber
          min={0}
          max={max}
          precision={3}
          value={value}
          onChange={value => {
            if (value) {
              onChange(value)
            }
          }}
          addonAfter={props.displayUnit}
        />
      </div>
      {extra && <div>{extra}</div>}
    </div>
  )
}
