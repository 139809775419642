import { message } from 'antd'
import React from 'react'
import {
  type EmissionsItemsCategoriesRelatedToPermissionQuery,
  PermissionName,
  useEmissionsItemsCategoriesRelatedToPermissionQuery
} from 'src/generated/graphql/types'
import { useTypedTranslation } from '../common/utils'
import CenteredLoader from '../common/utils/CenteredLoader'

interface CategoryProviderProps {
  children: React.ReactNode
  permission: PermissionName
  hideLoader?: boolean
}

interface CategoriesContextInterface {
  allEmissionItemsCategories: EmissionsItemsCategoriesRelatedToPermissionQuery['emissionsItemsCategoriesRelatedToPermission']
}

export const CategoriesContext = React.createContext<CategoriesContextInterface>({
  allEmissionItemsCategories: []
})

export function CategoriesProvider({ children, permission, hideLoader }: CategoryProviderProps) {
  const { data, loading, error } = useEmissionsItemsCategoriesRelatedToPermissionQuery({
    variables: {
      permission
    }
  })
  const { t } = useTypedTranslation()

  if (loading) {
    return hideLoader ? null : <CenteredLoader fullHeight={false} />
  }

  if (error || !data) {
    message.error(t('common.error_message'))
    console.error(error)
    return null
  }

  return (
    <CategoriesContext.Provider
      value={{
        allEmissionItemsCategories: data.emissionsItemsCategoriesRelatedToPermission
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}
