import { Link, type LinkProps, useParams } from 'react-router-dom'
import isString from 'lodash/fp/isString'
import { generateOrganizationLink } from 'src/navigation/GenerateOrganizationLink'

export function OrganizationLink(props: LinkProps) {
  const { organizationId } = useParams<'organizationId'>()
  const to = isString(props.to) ? generateOrganizationLink(props.to, organizationId) : props.to

  return <Link {...props} to={to} />
}
