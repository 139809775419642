import { useEffect } from 'react'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { UserInfos } from 'src/models/UserInfos'
import { useOrganizationConfig } from 'src/provider'
import { useOverridableFlag } from '../KeyShortcuts/Shortcuts/useOverridableFlag'

function addCrispScript() {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = 'fdc1354f-6094-4f38-9cb0-08a05aef9e18'
  ;(function () {
    const d = document
    const s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    ;(s as any).async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

function initializeCrisp(params: {
  currentUser: UserInfos
  organization: {
    id: string
    name: string
  }
}) {
  const { currentUser, organization } = params
  window.$crisp.push(['set', 'user:email', [currentUser.email]])
  if (currentUser.fullName) {
    window.$crisp.push(['set', 'user:nickname', [currentUser.fullName]])
  }
  window.$crisp.push(['set', 'user:company', [organization.name]])

  const segments = [`organizationId:${organization.id}`]
  if (currentUser.email.includes('@traace.co')) {
    segments.push('Staff')
  }
  window.$crisp.push(['set', 'session:segments', [segments]])
}

export function useCrisp() {
  const { currentUser, organization } = useOrganizationConfig()
  const isCrispCTAHidden = useOverridableFlag(UnleashFlag.HideCrispCTA)
  useEffect(() => {
    if (!isCrispCTAHidden) {
      addCrispScript()
      initializeCrisp({ currentUser, organization })
    }
  }, [])
  return null
}
