import ReactECharts, { type EChartsOption } from 'echarts-for-react'
import { useTypedTranslation } from 'src/common/utils'
import useIsMobile from '../utils/useIsMobile'
import { estimateFootprint } from 'src/modules/lcas/templateDetails/presets/computation/footprintEstimator'

export function HorizontalBarChart(props: {
  preset: {
    resolvedComponents: {
      id: string
      value: number
      variant: {
        impactComponent: {
          name: string
          color: string
          unitEntity: {
            conversionFactor: number
          }
        }
        name: string
        emissionFactor: {
          valuePerReferenceUnit: number
          referenceUnitEntity: {
            conversionFactor: number
          }
        }
      }
    }[]
  }
  maxTotalFootprintKgCO2e?: number
}) {
  const { preset, maxTotalFootprintKgCO2e } = props

  const { t } = useTypedTranslation()

  const isMobile = useIsMobile()

  // Naive way to find the best ceiling value
  let maxCeilingValue = 1
  let maxTotalFootprintKgCO2eRounded: number | undefined
  if (maxTotalFootprintKgCO2e) {
    maxTotalFootprintKgCO2eRounded = maxTotalFootprintKgCO2e
    while (maxTotalFootprintKgCO2eRounded > 100) {
      maxTotalFootprintKgCO2eRounded /= 10
      maxCeilingValue *= 10
    }
  }

  function shouldShowLabel(value: number) {
    if (maxTotalFootprintKgCO2e === 0 || !maxTotalFootprintKgCO2e) {
      return true
    }
    // Hide labels that have not enough space to display
    return Math.abs(value / maxTotalFootprintKgCO2e) > 0.05
  }

  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    legend: false,
    grid: {
      left: '3%',
      right: '4%',
      top: '10%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      max: maxTotalFootprintKgCO2e ? Math.ceil(maxTotalFootprintKgCO2e / maxCeilingValue) * maxCeilingValue : undefined
    },
    yAxis: {
      type: 'category',
      data: [t('lca_templates.presets.details.chart_title')]
    },
    series: preset.resolvedComponents.map(resolvedComponent => ({
      name: `${resolvedComponent.variant.impactComponent.name} - ${resolvedComponent.variant.name}`,
      type: 'bar',
      itemStyle: {
        color: resolvedComponent.variant.impactComponent.color
      },
      stack: 'total',
      label: {
        show: true,
        formatter: function (params: any) {
          if (!shouldShowLabel(params.data)) {
            return ''
          }
          return params.data
        }
      },
      emphasis: {
        focus: 'series'
      },
      data: [
        Math.round(
          estimateFootprint({
            value: resolvedComponent.value,
            impactFactor: resolvedComponent.variant.emissionFactor,
            component: resolvedComponent.variant.impactComponent
          })
        )
      ]
    }))
  }

  return (
    <div className="w-full relative">
      <ReactECharts style={{ height: isMobile ? '100px' : '200px' }} option={chartOptions} notMerge />
    </div>
  )
}
