import { message } from 'antd'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CenteredLoader, useTypedTranslation } from 'src/common/utils'
import { useGetSharedLcaTemplateQuery } from 'src/generated/graphql/types'
import { estimateTotalFootprintForPreset } from 'src/modules/lcas/templateDetails/presets/computation/footprintEstimator'
import { usePresetFilter } from 'src/modules/lcas/templateDetails/presets/hooks/usePresetFilter'
import type { LCAPreset } from 'src/modules/lcas/templateDetails/presets/model/LCAPreset'
import { SharedLCAFooter } from 'src/modules/shared/_components/SharedLCAFooter'
import { SharedLCAPresetItem } from 'src/modules/shared/lcaTemplates/SharedLCAPresetItem'
import { generateApolloContextForSharedToken } from 'src/modules/shared/utils/sharingTokenHeaderGenerator'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function SharedLCAPresets() {
  const query = useQuery()
  const sharingToken = query.get('token')!
  const [updatedPresets, setUpdatedPresets] = useState<LCAPreset[] | undefined>(undefined)
  const { data, loading, error } = useGetSharedLcaTemplateQuery({
    variables: {
      input: {
        sharingToken
      }
    },
    context: generateApolloContextForSharedToken({ sharingToken })
  })
  const { presets: filteredPresets, filterComponent } = usePresetFilter(data?.getSharedLCATemplate.presets)
  const { t } = useTypedTranslation()
  if (loading) {
    return <CenteredLoader />
  }
  if (!data || !filteredPresets || error) {
    console.error(error)
    message.error(t('common.error'))
    return null
  }

  // By default, use the remote presets. If the user updates a preset locally, use the updated presets instead.
  const presets = updatedPresets ?? filteredPresets

  const maxTotalFootprintKgCO2e = Math.max(
    ...filteredPresets.map(preset => estimateTotalFootprintForPreset({ preset }))
  )

  return (
    <div className="font-Inter">
      <div className="h-full w-full absolute -z-50" style={{ backgroundColor: '#2B225A' }} />
      <div className="p-8 bg-white">
        <div className="flex flex-col gap-4">
          <div className="font-medium text-lg">{data.getSharedLCATemplate.title}</div>
          <div className="text-sm">{data.getSharedLCATemplate.description}</div>
          <div className="text-sm">{filterComponent}</div>
          <div className="flex flex-col gap-2">
            {presets.map(preset => (
              <SharedLCAPresetItem
                key={preset.id}
                preset={preset}
                maxTotalFootprintKgCO2e={maxTotalFootprintKgCO2e}
                sharingToken={sharingToken}
                onResolvedImpactComponentUpdateRequest={(params: any) => {
                  setUpdatedPresets(
                    [...presets].map(presetToUpdate => {
                      if (presetToUpdate.id !== preset.id) {
                        return presetToUpdate
                      }
                      return {
                        ...presetToUpdate,
                        resolvedComponents: presetToUpdate.resolvedComponents.map(resolvedImpactComponent => {
                          if (resolvedImpactComponent.id === params.resolvedImpactComponentToUpdateId) {
                            return {
                              ...resolvedImpactComponent,
                              value: params.value ?? resolvedImpactComponent.value,
                              variant: {
                                ...resolvedImpactComponent.variant,
                                ...params.variant
                              }
                            }
                          }
                          return resolvedImpactComponent
                        })
                      }
                    })
                  )
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <SharedLCAFooter />
    </div>
  )
}
