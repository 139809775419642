import React from 'react'
import type { EntitiesNodesContextInterface } from 'src/provider/EntitiesNodesProvider'

export const EntitiesNodesContext = React.createContext<EntitiesNodesContextInterface>({
  allContributingEntities: [],
  allOrganizationNodes: [],
  ancestorOrganizationNodes: [],
  loaded: false,
  refetch: () => null
})
