import { FlagProvider, useUnleashContext } from '@unleash/proxy-client-react'
import React, { type ReactNode, useEffect, useState } from 'react'
import CenteredLoader from 'src/common/utils/CenteredLoader'
import { UserInfos } from 'src/models/UserInfos'
import { useOrganizationConfig } from 'src/provider'
import type { IMutableContext } from 'unleash-proxy-client'
import { unleashConfig } from 'src/services/unleash/unleashConfig'
import { UnleashOverrideFlagProvider } from 'src/services/KeyShortcuts/Shortcuts/UnleashOverrideFlagProvider'

async function initializeUnleash(params: {
  user: UserInfos
  organization: {
    id: string
    name: string
  }
  unleashContext: (context: IMutableContext) => Promise<void>
}) {
  const { user, organization, unleashContext } = params

  return await unleashContext({
    userId: user.id ?? undefined,
    properties: {
      organizationId: organization.id,
      organizationName: organization.name,
      email: user.email,
      branch: import.meta.env.VITE_ENV ?? 'development'
    }
  })
}

function UnleashInitialization({ children }: { children: ReactNode }) {
  const unleashContext = useUnleashContext()
  const [unleashReady, setUnleashReady] = useState(false) // the useFlagsStatus flagsReady doesn't work in our case, so we need to use our own state
  const [unleashError, setUnleashError] = useState(false)
  const { currentUser, organization } = useOrganizationConfig()

  useEffect(() => {
    if (currentUser && organization) {
      initializeUnleash({ user: currentUser, organization, unleashContext })
        .catch(() => {
          if (!unleashError) {
            setUnleashError(true)
          }
        })
        .finally(() => {
          if (!unleashReady) {
            setUnleashReady(true)
          }
        })
    }
  }, [currentUser, organization])

  if (!unleashReady) {
    return <CenteredLoader />
  }

  return children
}

export function UnleashProvider({ children }: { children: React.ReactNode }) {
  return (
    <FlagProvider config={unleashConfig}>
      <UnleashInitialization>
        <UnleashOverrideFlagProvider>{children}</UnleashOverrideFlagProvider>
      </UnleashInitialization>
    </FlagProvider>
  )
}
