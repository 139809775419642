import type { Metric } from 'src/type'
import { useMetrics } from 'src/common/hooks'
import { TranslatedValue } from 'src/common/designSystem/TranslatedValue'
import React from 'react'

export function MetricLabel({ metric }: { metric: Pick<Metric, 'organization' | 'label' | 'id'> }) {
  const { getReferenceUnitLabel } = useMetrics()

  return (
    <div className="flex gap-1 items-center">
      {!metric.organization?.id && <img alt="Traace metric" src="/favicon-32.png" className="w-4 h-4" />}
      <TranslatedValue fields={metric.label} />
      <span>({getReferenceUnitLabel(metric.id)})</span>
    </div>
  )
}
