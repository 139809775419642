import React, { type Dispatch, type SetStateAction } from 'react'

const CONTEXT_DEFAULT_VALUE = {
  startResize: false,
  setStartResize: () => null,
  initialX: 0,
  setInitialX: () => null
}
export const ImageDecoratorContext = React.createContext<{
  startResize: boolean
  initialX: number
  setStartResize: Dispatch<SetStateAction<boolean>>
  setInitialX: Dispatch<SetStateAction<number>>
}>(CONTEXT_DEFAULT_VALUE)
