import { useFilterField } from 'src/common/hooks'

const DEFAULT_LIMIT = 10
const DEFAULT_OFFSET = 0

export const useSortOrder = (setCurrentPage: (pageNumber: number) => void) => {
  const pathName = window.location.pathname
  const [order, setOrder] = useFilterField<'ASC' | 'DESC'>({
    urlKey: 'order',
    defaultValue: 'ASC',
    type: 'string',
    sessionStorageKey: `${pathName}/order`
  })

  const [sort, setSort] = useFilterField<string>({
    urlKey: 'sort',
    type: 'string',
    sessionStorageKey: `${pathName}/sort`
  })

  return {
    order,
    setOrder: (sortOrder: 'ASC' | 'DESC') => {
      setOrder(sortOrder)
      setCurrentPage(1)
    },
    sort,
    setSort: (sortingKey: string) => {
      setSort(sortingKey)
      setCurrentPage(1)
    }
  }
}

export const usePagination = (params?: {
  offsetKey?: string
  limit?: number
  limitKey?: string
}): {
  offset: number
  currentPage?: number
  limit: number
  onChange: (newCurrent: number, newPageSize: number) => void
  handleRemoveItem: (dataLength: number | undefined) => void
  handleRemoveItems: (dataLength: number | undefined, removedItemsLength: number) => void
  handleFilterChange: () => void
  setCurrentPage: (pageNumber: number) => void
} => {
  const pathName = window.location.pathname

  const [storedOffset, setOffset] = useFilterField<number>({
    defaultValue: DEFAULT_OFFSET,
    type: 'number',
    urlKey: params?.offsetKey ?? 'offset',
    sessionStorageKey: params?.offsetKey ? `${pathName}/${params.offsetKey}` : `${pathName}/offset`
  })

  const [storedLimit, setLimit] = useFilterField<number>({
    defaultValue: params?.limit ?? DEFAULT_LIMIT,
    type: 'number',
    urlKey: params?.limitKey ?? 'limit',
    sessionStorageKey: params?.limitKey ? `${pathName}/${params.limitKey}` : `${pathName}/limit`
  })

  const limit = storedLimit ?? DEFAULT_LIMIT
  const offset = storedOffset ?? DEFAULT_OFFSET

  const currentPage = offset / limit + 1
  const setCurrentPage = (pageNumber: number) => setOffset(Math.round((pageNumber - 1) * limit))

  const onChange = (newCurrent: number, newPageSize: number) => {
    if (limit !== newPageSize) {
      setCurrentPage(1)
      setLimit(newPageSize)
    } else {
      setCurrentPage(newCurrent)
    }
  }

  //handle bulk delete
  const handleRemoveItems = (dataLength: number | undefined, removedItemsLength: number) => {
    if (currentPage && currentPage > 1 && dataLength === removedItemsLength) setCurrentPage(currentPage - 1)
  }

  //handle one item removal
  const handleRemoveItem = (dataLength: number | undefined) => {
    if (currentPage && currentPage > 1 && dataLength === 1) setCurrentPage(currentPage - 1)
  }

  const handleFilterChange = () => {
    setCurrentPage(1)
  }

  return {
    offset,
    currentPage,
    limit,
    onChange,
    handleRemoveItem,
    handleFilterChange,
    handleRemoveItems,
    setCurrentPage
  }
}
