import React from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { SearchParamsContextProvider } from 'src/common/providers'
import { DefaultPageNavigate } from 'src/navigation/DefaultPageNavigate'
import { EmissionsExportDownloadProvider } from 'src/provider/EmissionsExportDownloadProvider'
import { useBeamer } from 'src/services/beamer'
import { useCrisp } from 'src/services/crisp'
import { useMixpanel } from 'src/services/mixpanel'
import { usePlanhat } from 'src/services/planhat'
import { UnleashProvider } from 'src/services/unleash'
import WelcomePage from 'src/authentication/WelcomePage'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import NavContainer from 'src/navigation/NavContainer'
import { ConfigProvider } from 'src/provider'
import { ReportingStandard } from 'src/generated/graphql/types'
import { KeyShortcuts } from 'src/services/KeyShortcuts/KeyShortcuts'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useBuildOrganizationLink } from 'src/navigation/UseBuildOrganizationLink'
import { SupplyChainLazyModule } from 'src/modules/supplyChain/SupplyChainLazyModule'
import { LazyLoadFallback } from 'src/app/LazyLoadFallback'
import { ConnectLazyModule } from 'src/modules/connect/ConnectLazyModule'
import { ConnectLazyModule as ConnectLazyModuleV2 } from 'src/modules/measure/connect/ConnectLazyModule'
import { MyTasksLazyModule } from 'src/modules/myTasks/MyTasksLazyModule'
import { QuestionnairesLazyModule } from 'src/modules/questionnaires/QuestionnairesLazyModule'
import { CampaignsLazyModule } from 'src/modules/campaigns/CampaignsLazyModule'
import { MappingLazyModule } from 'src/modules/measure/mappings/MappingLazyModule'
import { LCALazyModule } from 'src/modules/lcas/LCALazyModule'
import { TrajectoryLazyModule } from 'src/modules/reduce/trajectory/TrajectoryLazyModule'
import { ImportDetailsLazyModule } from 'src/modules/connect/import/ImportDetailsLazyModule'
import { ActionPlansLazyModule } from 'src/modules/reduce/actionPlans/ActionPlansLazyModule'
import { ActionTemplatesLazyModule } from 'src/modules/reduce/actionTemplates/ActionTemplateLazyModule'
import { ActionDetailsLazyModule } from 'src/modules/reduce/actionPlans/actionDetails/ActionDetailsLazyModule'
import { ContributionProjectsCatalogLazyModule } from 'src/modules/carbonContribution/ContributionProjectsCatalogLazyModule'
import { DataCollectionLazyModule } from 'src/modules/measure/dataCollection/DataCollectionLazyModule'
import { DashboardsLazyModule } from 'src/modules/analysis/DashboardsLazyModule'
import { SettingsLazyModule } from 'src/modules/settings/SettingsLazyModule'
import { ContributingSourceDetailsLazyModule } from 'src/dataCollection/ContributingSourceDetails/ContributingSourceDetailsLazyModule'
import { ConnectContextProvider } from 'src/modules/connect/import/_provider/ConnectContext'
import { OrganizationSortedLanguageProvider } from 'src/provider/OrganizationSortedLanguageProvider/OrganizationSortedLanguageProvider'
import { DataOriginsContextProvider } from 'src/dataCollection/DataOriginsContext'
import { ConnectV2Redirect } from 'src/app/organization/ConnectV2Redirect'

// we lazy load this module because it uses a large dependency : https://redocly.com/docs/redoc/deployment/react/
const PublicAPIDocumentation = React.lazy(() => import('src/modules/apidoc/PublicAPIDocumentation'))
const ReportingModule = React.lazy(() => import('src/modules/reporting/ReportingModule'))

const RedirectUserDetails = () => {
  const { authUserId } = useParams<'authUserId'>()
  const { generateOrganizationLink } = useBuildOrganizationLink()
  return <Navigate replace to={generateOrganizationLink(`/settings/team/users/${authUserId}`)} />
}

function AppRoutes() {
  const offsetProjectsEnabled = useOverridableFlag(UnleashFlag.OffsetProjectsEnabled)
  const isConnectV1Enabled = useOverridableFlag(UnleashFlag.ConnectV1)
  const { generateOrganizationLink } = useBuildOrganizationLink()
  return (
    <Routes>
      <Route path="*" element={<NavContainer />}>
        <Route path="welcome" element={<WelcomePage />} />
        <Route
          path="dataCollection/*"
          element={
            <LazyLoadFallback>
              <DataCollectionLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="dataCollection/analyses/:analysisId/contributingSource/:contributingSourceId"
          element={
            <LazyLoadFallback>
              <ContributingSourceDetailsLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="analyses/:analysisId"
          element={<Navigate replace to={generateOrganizationLink('/analyses/dashboards/list')} />}
        />
        <Route
          path="analyses/*"
          element={
            <LazyLoadFallback>
              <DashboardsLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="settings/*"
          element={
            <LazyLoadFallback>
              <SettingsLazyModule />
            </LazyLoadFallback>
          }
        />
        {/*Legacy Redirection (to delete 1st january 2025? Ask for it to product team maybe)*/}
        <Route
          path="organization/*"
          element={<Navigate replace to={generateOrganizationLink('/settings/organization/tree')} />}
        />
        <Route
          path="categories/*"
          element={<Navigate replace to={generateOrganizationLink('/settings/data-management/categories')} />}
        />
        <Route
          path="settings/dataOrigins"
          element={<Navigate replace to={generateOrganizationLink('/settings/data-management/data-origins')} />}
        />
        <Route
          path="settings/welcome-page"
          element={<Navigate replace to={generateOrganizationLink('/settings/organization/welcome-page')} />}
        />
        <Route
          path="settings/roles"
          element={<Navigate replace to={generateOrganizationLink('/settings/team/roles')} />}
        />
        <Route
          path="settings/metrics"
          element={<Navigate replace to={generateOrganizationLink('/settings/data-management/metrics')} />}
        />
        <Route path="settings/users/:authUserId" element={<RedirectUserDetails />} />
        <Route
          path="settings/emissionsFactors"
          element={<Navigate replace to={generateOrganizationLink('/settings/data-management/emissions-factors')} />}
        />
        <Route
          path="settings/dataPointTags"
          element={<Navigate replace to={generateOrganizationLink('/settings/data-management/tags')} />}
        />
        {/*end of Legacy Redirection (to delete 1st january 2025? Ask for it to product team maybe)*/}
        {/* Connect_V2 redirections */}
        <Route path="connect_v2/*" element={<ConnectV2Redirect />} />
        {!isConnectV1Enabled && (
          <Route path="connect/*" element={<Navigate replace to={generateOrganizationLink('/imports_v2/')} />} />
        )}
        {/* end of Connect_V2 redirections */}
        <Route
          path="supplyChain/:tabKey?"
          element={
            <LazyLoadFallback>
              <SupplyChainLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          element={
            <ConnectContextProvider>
              <LazyLoadFallback>
                <Outlet />
              </LazyLoadFallback>
            </ConnectContextProvider>
          }
        >
          <Route
            path="connect/*"
            element={
              <LazyLoadFallback>
                <ConnectLazyModule />
              </LazyLoadFallback>
            }
          />
          <Route
            path="imports/:importId"
            element={
              <LazyLoadFallback>
                <ImportDetailsLazyModule />
              </LazyLoadFallback>
            }
          />
          <Route
            path="imports_v2/:tabKey?/*"
            element={
              <LazyLoadFallback>
                <ConnectLazyModuleV2 />
              </LazyLoadFallback>
            }
          />
        </Route>
        <Route
          path="mytasks/*"
          element={
            <LazyLoadFallback>
              <MyTasksLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="questionnaires/*"
          element={
            <LazyLoadFallback>
              <QuestionnairesLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="campaigns/*"
          element={
            <LazyLoadFallback>
              <CampaignsLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="mappings/*"
          element={
            <LazyLoadFallback>
              <MappingLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="actionPlans/*"
          element={
            <LazyLoadFallback>
              <ActionPlansLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="actionTemplates/*"
          element={
            <LazyLoadFallback>
              <ActionTemplatesLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="actions/:actionId"
          element={
            <LazyLoadFallback>
              <ActionDetailsLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="trajectory"
          element={
            <LazyLoadFallback>
              <TrajectoryLazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="lcaTemplates/*"
          element={
            <LazyLoadFallback>
              <LCALazyModule />
            </LazyLoadFallback>
          }
        />
        <Route
          path="api-documentation"
          element={
            <LazyLoadFallback>
              <PublicAPIDocumentation />
            </LazyLoadFallback>
          }
        />
        {offsetProjectsEnabled && (
          <Route
            path="contributionProjectsCatalog/*"
            element={
              <LazyLoadFallback>
                <ContributionProjectsCatalogLazyModule />
              </LazyLoadFallback>
            }
          />
        )}
        <Route
          path="reporting/*"
          element={
            <LazyLoadFallback>
              <ReportingModule reportingStandard={ReportingStandard.Csrd} />
            </LazyLoadFallback>
          }
        />
        <Route index element={<DefaultPageNavigate />} />
      </Route>
    </Routes>
  )
}

function ServicesProvider({ children }: { children: React.ReactNode }) {
  usePlanhat()
  useCrisp()
  useBeamer()
  useMixpanel()
  return children
}

export function Main() {
  return (
    <ConfigProvider>
      <OrganizationSortedLanguageProvider>
        <UnleashProvider>
          <ServicesProvider>
            <KeyShortcuts>
              <EmissionsExportDownloadProvider>
                <SearchParamsContextProvider>
                  <DataOriginsContextProvider>
                    <AppRoutes />
                  </DataOriginsContextProvider>
                </SearchParamsContextProvider>
              </EmissionsExportDownloadProvider>
            </KeyShortcuts>
          </ServicesProvider>
        </UnleashProvider>
      </OrganizationSortedLanguageProvider>
    </ConfigProvider>
  )
}
