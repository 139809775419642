import type { DefaultContext } from '@apollo/client'

function generateSharedTokenHeader(params: { sharingToken: string }): object {
  const { sharingToken } = params
  return { 'x-sharing-token': sharingToken }
}

export function generateApolloContextForSharedToken(params: { sharingToken: string }): DefaultContext {
  const { sharingToken } = params
  return { headers: generateSharedTokenHeader({ sharingToken }) }
}
