import { OrganizationButton } from 'src/modules/settings/team/team/userDetails/_components/OrganizationButton'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { useOrganizationConfig } from 'src/provider'

const Profile = () => {
  const { currentUser, organization } = useOrganizationConfig()

  return (
    <div className="flex space-x-2 h-full">
      <div className="flex flex-col overflow-hidden justify-between py-1 gap-1" style={{ lineHeight: '18px' }}>
        <div className="truncate w-full font-medium cursor-pointer relative">
          <OrganizationLink to={`/settings/users/${currentUser.authUser.id}`}>{currentUser.fullName}</OrganizationLink>
        </div>
        <div className="truncate overflow-y-visible w-full font-light cursor-auto relative">
          <OrganizationButton organizationName={organization.name} compactMode />
        </div>
      </div>
    </div>
  )
}

export default Profile
