import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import { type AnalysisGroup, useAnalysisGroupsQuery } from 'src/generated/graphql/types'
import isEmpty from 'lodash/fp/isEmpty'
import first from 'lodash/fp/first'
import get from 'lodash/fp/get'
import last from 'lodash/fp/last'

const getLastAnalysisId = flow(
  filter<AnalysisGroup>(analyse => !isEmpty(analyse.analyses)),
  first,
  get('analyses'),
  last,
  get('id')
)

export function useAnalysis() {
  const { loading, data } = useAnalysisGroupsQuery()
  return {
    loading,
    data,
    lastAnalysisId: getLastAnalysisId(data?.listAnalysisGroups)
  }
}
