import { type PropsWithChildren, useEffect, useState } from 'react'
import { useOrganizationConfig } from 'src/provider'
import { UnleashFlagsOverrideModal } from 'src/services/KeyShortcuts/Shortcuts/UnleashFlagsOverrideModal'

export const KeyShortcuts = ({ children }: PropsWithChildren) => {
  const { isTraaceAdmin } = useOrganizationConfig()
  const [displayUnleashFlagsOverrideModal, setDisplayUnleashFlagsOverrideModal] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isTraaceAdmin) return
      if (e.ctrlKey && e.shiftKey) {
        e.preventDefault()
        if (e.key === 'U') {
          setDisplayUnleashFlagsOverrideModal(true)
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  return (
    <>
      <UnleashFlagsOverrideModal
        open={displayUnleashFlagsOverrideModal}
        onCancel={() => setDisplayUnleashFlagsOverrideModal(false)}
      />
      {children}
    </>
  )
}
