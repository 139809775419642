import isEmpty from 'lodash/isEmpty'
import { AdminRole, Language, type UserInfosFragment } from 'src/generated/graphql/types'

export class UserInfos implements UserInfosFragment {
  id: string
  authUser: {
    id: string
    firstName: string | null
    lastName: string | null
    email: string
    pictureUrl: string | null
    adminRole: AdminRole | null
    language: Language | null
    isDeleted: boolean
  }

  constructor(responseUser: UserInfosFragment) {
    if (!responseUser.id) {
      throw new Error('User id is required')
    }

    if (!responseUser.authUser) {
      throw new Error('User authUser is required')
    }

    if (!responseUser.authUser.id) {
      throw new Error('User authUser id is required')
    }

    if (!responseUser.authUser.email) {
      throw new Error('User authUser email is required')
    }

    this.id = responseUser.id

    this.authUser = {
      id: responseUser.authUser.id,
      firstName: responseUser.authUser.firstName ?? null,
      lastName: responseUser.authUser.lastName ?? null,
      email: responseUser.authUser.email,
      pictureUrl: responseUser.authUser.pictureUrl ?? null,
      adminRole: responseUser.authUser.adminRole ?? null,
      language: responseUser.authUser.language ?? null,
      isDeleted: responseUser.authUser.isDeleted
    }
  }

  get initials(): string {
    if (this.firstName && this.lastName) {
      return (this.firstName.charAt(0) + this.lastName.charAt(0)).toUpperCase()
    }
    return this.email.charAt(0).toUpperCase()
  }

  get email(): string {
    return this.authUser.email
  }

  get firstName(): string | null {
    return this.authUser.firstName
  }

  get lastName(): string | null {
    return this.authUser.lastName
  }

  get fullName(): string | null {
    if (this.firstName == null || this.lastName == null) {
      return null
    }
    return `${this.firstName} ${this.lastName}`
  }

  get hasIncompleteRegistration() {
    return this.firstName == null || this.lastName == null
  }
}

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
): string | null => {
  if (isEmpty(firstName) && isEmpty(lastName)) {
    return null
  }
  return `${firstName ?? ''} ${lastName ?? ''}`.trim()
}
