import React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { useConvertQuillToLexical } from './hooks'
import { HeadingNode } from '@lexical/rich-text'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import classNames from 'classnames'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { EditorToolbarPlugin, ResetStyleOnEnterPlugin } from 'src/common/editor/plugins'
import { ImageNodeDecorator } from 'src/common/editor/nodes/image'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import type { LexicalEditor, SerializedEditorState, SerializedLexicalNode } from 'lexical'
import isUndefined from 'lodash/fp/isUndefined'
import urlRegexSafe from 'url-regex-safe'
import isString from 'lodash/fp/isString'

type TraaceEditorProps = {
  data?: string | object | null
  readOnly?: boolean
  onChange?: (editorState: SerializedEditorState<SerializedLexicalNode>) => void
}

function getJsonData(data: string | object | null | undefined) {
  if (isString(data)) {
    try {
      return JSON.parse(data)
    } catch (e) {
      console.error(e)
      return {}
    }
  }
  return data
}

function isLexicalJson(data: any) {
  return !isUndefined(data?.['root'])
}

function validateUrl(url: string): boolean {
  return urlRegexSafe({ exact: true }).test(url)
}

const theme = {
  paragraph: 'traace-editor-paragraph',
  link: 'traace-editor-link',
  text: {
    bold: 'traace-editor-textBold',
    code: 'traace-editor-textCode',
    italic: 'traace-editor-textItalic',
    strikethrough: 'traace-editor-textStrikethrough',
    underline: 'traace-editor-textUnderline',
    underlineStrikethrough: 'traace-editor-textUnderlineStrikethrough'
  }
}

export function TraaceEditor({ data, readOnly = false, onChange = () => null }: TraaceEditorProps) {
  const json = getJsonData(data)

  const { getInitialValue } = useConvertQuillToLexical({ data: json })

  const initialConfig = {
    namespace: 'TraaceEditor',
    theme,
    nodes: [ImageNodeDecorator, HeadingNode, LinkNode, AutoLinkNode],
    editable: !readOnly,
    editorState: (editor: LexicalEditor) => {
      if (isLexicalJson(json)) {
        editor.setEditorState(editor.parseEditorState(json))
      } else {
        getInitialValue()
      }
    },
    onError: (error: any) => console.error(error)
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {!readOnly ? <EditorToolbarPlugin /> : ''}
      <OnChangePlugin onChange={editorState => onChange(editorState.toJSON())} />
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={classNames('p-4', { 'bg-white border border-solid border-greengrey-20': !readOnly })}
          />
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <LinkPlugin validateUrl={validateUrl} />
      <HistoryPlugin />
      <ResetStyleOnEnterPlugin />
    </LexicalComposer>
  )
}
