import { CompassOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/KeyShortcuts/Shortcuts/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import { CONTRIBUTION_PROJECTS_CATALOG_ROUTE, NET_ZERO_MENU } from '../routes'
import { type GetProp, Menu } from 'antd'

export const useNetZeroMenu = (): GetProp<typeof Menu, 'items'>[number] | null => {
  const { t } = useTypedTranslation()
  const offsetProjectsEnabled = useOverridableFlag(UnleashFlag.OffsetProjectsEnabled)

  if (!offsetProjectsEnabled) return null

  return {
    key: `${NET_ZERO_MENU} ${CONTRIBUTION_PROJECTS_CATALOG_ROUTE}/*`,
    icon: <CompassOutlined />,
    label: <OrganizationLink to="/contributionProjectsCatalog">{t('menu.net_zero')}</OrganizationLink>
  }
}
