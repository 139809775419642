import { setTwoToneColor } from '@ant-design/icons'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import antFrLocale from 'antd/locale/fr_FR'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { getAnonymousApolloClient } from 'src/common/apollo/apolloClient'
import { primaryColor, useTypedTranslation } from 'src/common/utils'
import { SharedLCAPresets } from 'src/modules/shared/lcaTemplates/SharedLCAPresets'
import 'src/App.css'

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)

export function SharedContainer() {
  setTwoToneColor(primaryColor)
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  return (
    <div className="App">
      <ApolloProvider client={getAnonymousApolloClient()}>
        <ConfigProvider locale={i18n.language === 'fr' ? antFrLocale : antEnLocale}>
          <Routes>
            <Route path="lcaTemplates">
              <Route path="presets" element={<SharedLCAPresets />} />
            </Route>
          </Routes>
        </ConfigProvider>
      </ApolloProvider>
    </div>
  )
}
