import { createContext } from 'react'
import { type DataOriginsQuery } from 'src/generated/graphql/types'

type DataOriginsContextContent = {
  loading: boolean
  dataOrigins: DataOriginsQuery['listDataOrigins']
  defaultDataOrigin?: DataOriginsQuery['listDataOrigins'][number]
}

export const DataOriginsContext = createContext<DataOriginsContextContent>({
  loading: false,
  dataOrigins: [] as DataOriginsQuery['listDataOrigins'],
  defaultDataOrigin: undefined
})
